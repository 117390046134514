import React from 'react'
import * as st from '~/assets/styl/404.module.styl'
import bauerLogo from '~/assets/svg/svg-logo-red.svg'
import { Link } from 'gatsby'

const NotFound = () => (
  <>
    <div className={st.content1364}>
      <Link to="/">
        <img src={bauerLogo} alt="Logo da Bauer Postos" />
      </Link>
      <div className={st.notFound}>
        <h2>
          Ops! <br />
          Página não encontrada
        </h2>
        <p>
          A página que você tentou acessar não existe ou está fora do ar
          temporariamente. Verifique o endereço do link e tente outra vez.
        </p>
        <div>
          <Link to="/">Ir para a Home</Link>
        </div>
      </div>
    </div>
  </>
)

export default NotFound
